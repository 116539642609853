export * from './src/any-kit'
export * from './src/array-kit'
export * from './src/byte-kit'
export * from './src/object-kit'
export * from './src/calendar-kit'
export * from './src/collection-kit'
export * from './src/currency-kit'
export * from './src/date-util-kit'
export * from './src/debug-kit'
export * from './src/enum-kit'
export * from './src/geometry-kit'
export * from './src/hash-kit'
export * from './src/stream-kit'
export * from './src/json-kit'
export * from './src/map-kit'
export * from './src/markdown-kit'
export * from './src/math-kit'
export * from './src/mime-kit'
export * from './src/promise-kit'
export * from './src/string-kit'
export * from './src/type-check-kit'
export * from './src/uuid-kit'
export * from './src/bookmark-kit'
export * from './src/Fund'

export * from './src/service-provider'
export * from './src/time-machine'

// Types
export * from './src/utility-types'
export * from './src/NupaHttpClient'
export * from './src/ClientCredentialsAuthProvider'
export * from './src/http-kit'
export * from './src/fluent-builder'
export * from './src/tuple-kit'

        import * as me from '@peachy/utility-kit-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/utility-kit/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/utility-kit-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    